<template>
  <a-spin tip="加载中" :spinning="loading">
    <div>
      <a-form-model :model="form" ref="newsCategoryForm" :rules="rules" class="laiwen"
        :class="{ disabled: form.status == 2 }">
        <div style="
            font-size: 20px;
            width: 100%;
            text-align: center;
            padding-bottom: 20px;
          ">
          来文阅办笺
        </div>
        <table class="formtable">
          <tr>
            <td style="width: 150px">
              <span style="color: red">*</span> 来文单位
            </td>
            <td colspan="2">
              <a-form-model-item prop="fromOrg">
                <a-input placeholder="请输入" v-model="form.fromOrg"></a-input>
              </a-form-model-item>
            </td>
            <td>文号</td>
            <td colspan="2">
              <a-form-model-item prop="number">
                <a-input placeholder="请输入" v-model="form.number" :maxLength="50"></a-input>
              </a-form-model-item>
            </td>
          </tr>
          <tr>
            <td><span style="color: red">*</span> 收文日期</td>
            <td style="text-align: left">
              <a-form-model-item prop="receiveTime">
                <a-date-picker v-model="form.receiveTime" />
              </a-form-model-item>
            </td>
            <td>紧急程度</td>
            <td style="text-align: left">
              <a-form-model-item prop="urgency">
                <a-input placeholder="请输入" v-model="form.urgency"></a-input>
              </a-form-model-item>
            </td>
            <td>密级</td>
            <td style="text-align: left">
              <a-form-model-item prop="confidentiality">
                <a-input placeholder="请输入" v-model="form.confidentiality"></a-input>
              </a-form-model-item>
            </td>
          </tr>
          <tr>
            <td><span style="color: red">*</span> 来文摘要</td>
            <td colspan="5">
              <a-form-model-item prop="summary">
                <a-textarea v-model="form.summary" style="height: 120px" />
              </a-form-model-item>
            </td>
          </tr>
          <tr>
            <td>文件正文</td>
            <td colspan="5" style="text-align: left">
              <a-upload :action="uploadFileUrl" :fileList="fileList" @change="handleFileChange" @preview="showWpsRead"
                :remove="() => upLoadRemove" style="pointer-events: all;">
                <div v-if="this.form.status == 0 || this.form.status == 3">
                  <a-button><a-icon type="upload" />上传文件</a-button>
                </div>
              </a-upload>
            </td>
          </tr>
          <tr style="height: 100px">
            <td>领导批示</td>
            <td colspan="5" style="text-align: left">
              <div style="padding-right: 83px">
                <auditshow :infoData="form" taskKey="来文阅办-领导批示" @callback="auditCb" />
              </div>
            </td>
          </tr>
          <tr style="height: 100px">
            <td rowspan="2">拟办意见</td>
            <td colspan="5">
              <div style="
                  float: left;
                  width: 100%;
                  min-height: 80px;
                  position: relative;
                ">
                <auditshow :infoData="form" taskKey="来文阅办-办公室副主任意见" :showRole="true" @callback="auditCb" />
                <div style="position: absolute; right: 50px; width: 220px">
                  <div style="text-align: left">
                    办公室副主任:
                    <auditUser :infoData="form" taskKey="来文阅办-办公室副主任意见" />
                  </div>
                  <div style="position: relative; text-align: left; left: 56px">
                    日期:
                    <auditDate :infoData="form" taskKey="来文阅办-办公室副主任意见" />
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr style="height: 100px">
            <td colspan="5">
              <div style="
                  float: left;
                  width: 100%;
                  min-height: 80px;
                  position: relative;
                ">
                <auditshow :infoData="form" taskKey="来文阅办-办公室主任意见" :showRole="true" @callback="auditCb" />
                <div style="position: absolute; right: 50px; width: 220px">
                  <div style="text-align: left; position: relative; left: 14px">
                    办公室主任:
                    <auditUser :infoData="form" taskKey="来文阅办-办公室主任意见" />
                  </div>
                  <div style="position: relative; text-align: left; left: 56px">
                    日期:
                    <auditDate :infoData="form" taskKey="来文阅办-办公室主任意见" />
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr style="height: 100px" v-if="
            form.processExecuteStatus &&
            form.taskKey == '来文阅办-办公室副主任协调承办'
          ">
            <td>选择承办院领导</td>
            <td colspan="5">
              <div style="width: 50%; float: left">
                <MultiSearchUser :checkUsers="selCBUser" @callback="selectCBusersCallback" />
              </div>
              <div style="float: left">
                <a-popconfirm title="提交后数据不可更改，是否确认？" @confirm="changeChenban(form)">
                  <a-button style="margin-left: 12px; margin-top: 2px" type="primary">提交</a-button></a-popconfirm>
              </div>
              <div style="float: left">
                <a-popconfirm title="选择办理完成后此流程结束，是否确认？" @confirm="taskComplate(1)">
                  <a-button style="margin-left: 12px; margin-top: 2px" type="primary">办理完成</a-button></a-popconfirm>
              </div>
              <auditdocshow ref="auditdocshow11" :infoData="form" :zhcheck="false" :uploadFile="false"
                taskKey="来文阅办-办公室副主任协调承办" @callback="auditCb" v-show="false" />
            </td>
          </tr>
          <tr style="height: 100px">
            <td>批阅承办意见</td>
            <td colspan="5">
              <div style="padding-right: 83px">
                <auditshow :infoData="form" taskKey="来文阅办-分管批阅承办意见" @callback="auditCb" />
              </div>
            </td>
          </tr>
          <tr style="height: 100px" v-if="
            form.processExecuteStatus &&
            form.taskKey == '来文阅办-办公室副主任协调办理'
          ">
            <td>选择办理人员</td>
            <td colspan="5">
              <div class="clearfix">
                <div style="width: 50%; float: left">
                  <MultiSearchUser :checkUsers="selBLUser" @callback="selectBLusersCallback" />
                </div>
                <div style="float: left">
                  <a-popconfirm title="提交后数据不可更改，是否确认？" @confirm="changeBanli(form)">
                    <a-button style="margin-left: 12px; margin-top: 2px" type="primary">提交</a-button></a-popconfirm>
                </div>
                <div style="float: left">
                  <a-popconfirm title="选择办理完成后此流程结束，是否确认？" @confirm="taskComplate(2)">
                    <a-button style="margin-left: 12px; margin-top: 2px" type="primary">办理完成</a-button></a-popconfirm>
                </div>
                <auditdocshow ref="auditdocshow12" :zhcheck="false" :uploadFile="false" :infoData="form"
                  taskKey="来文阅办-办公室副主任协调办理" @callback="auditCb" v-show="false" />
              </div>
              <div style="margin-top: 10px; text-align: left">
                <a-checkbox :checked="uploadAnnex" @change="uploadAnnexChange">
                  上传办理附件
                </a-checkbox>
              </div>
            </td>
          </tr>
          <tr style="height: 100px">
            <td>来文办理</td>
            <td colspan="5">
              <auditdocshow ref="auditdocshow13" :zhcheck="false" :btnText="'办理完成'" :uploadFile="true" :infoData="form"
                taskKey="来文阅办-来文办理" @callback="auditCb" @uploadDoc="uploadDoc" />
            </td>
          </tr>
        </table>
      </a-form-model>
      <div style="text-align: center; margin-top: 10px">
        <a-button v-if="form.status == 0 || form.status == 3" type="primary" @click="handleSubmit(0)"
          style="margin-right: 10px">保存</a-button>
        <a-popconfirm title="提交后数据不可更改，是否确认？" @confirm="handleSubmit(1)" v-if="form.status == 0 || form.status == 3">
          <a-button type="danger" style="margin-right: 10px">提交</a-button>
        </a-popconfirm>
        <a-button type="primary" v-if="form.status == 2 && allowFinish" style="margin-right: 10px"
          @click="finishTask()">办理完成</a-button>
        <a-button v-if="this.form.status == 2" @click="showWps" style="margin-right: 10px">打印</a-button>

        <a-button @click="cancelClick">取消</a-button>
      </div>
    </div>
  </a-spin>
</template>
<script>
  import documentreadApi from "@/api/work/documentread";
  import MultiSearchUser from "../components/MultiSearchUser.vue";
  import request from "@/request";
  import pinyin from "js-pinyin";
  import workflowApi from "@/api/workflow";
  export default {
    name: "createdocumentread",
    data() {
      return {
        loading: false,
        form: {
          status: 0,
          receiveTime: null,
          confidentiality: "无",
          chengbanusersName: "",
          chengbanusers: "",
          banliusers: "",
          banliusersName: "",
        },
        selCBUser: [],
        selBLUser: [],
        rules: {
          fromOrg: [
            { required: true, message: "请输入来文单位", trigger: "blur" },
          ],
          receiveTime: [
            { required: true, message: "请选择收文时间", trigger: "change" },
          ],
          // number: [{ required: true, message: "请输入文号", trigger: "blur" }],
          // urgency: [
          //   { required: true, message: "请输入紧急程度", trigger: "blur" },
          // ],
          // confidentiality: [
          //   { required: true, message: "请输入密级", trigger: "blur" },
          // ],
          summary: [
            { required: true, message: "请输入来文摘要", trigger: "blur" },
          ],
        },
        labelCol: { span: 5 },
        wrapperCol: { span: 17 },
        urgency: [],
        confidentiality: [],
        uploadFileUrl: "",
        upLoadRemove: false,
        fileList: [],
        allowFinish: false,
        currUser: localStorage.getItem(this.$commons.User.userName),
        uploadAnnex: false,
      };
    },
    props: {
      info: {
        type: Object,
      },
    },
    mounted() {
      this.form.receiveTime = this.$moment();
      this.uploadFileUrl = request.getUploadUrl("document");
      if (this.info && this.info.id > 0) {
        this.getData();
      } else this.upLoadRemove = true;
    },
    components: {
      MultiSearchUser,
    },
    methods: {
      uploadAnnexChange() {
        this.uploadAnnex = !this.uploadAnnex;
      },
      selectCBusersCallback(users) {
        this.selCBUser = [...users];
        this.form.chengbanusers = users.map((u) => u.userName).join(",");
        this.form.chengbanusersName = users.map((u) => u.realName).join(",");
      },
      selectBLusersCallback(users) {
        this.selBLUser = [...users];
        this.form.banliusers = users.map((u) => u.userName).join(",");
        this.form.banliusersName = users.map((u) => u.realName).join(",");
      },
      auditCb(data) {
        this.$emit("callback", data != null);
        this.getData();
      },
      handleFileChange(fileInfo) {
        this.fileList = fileInfo.fileList;
        if (fileInfo.file.status == "error") {
          this.$message.error("上传失败");
        }
      },
      getData() {
        this.loading = true;
        documentreadApi.getData(this.info.id).then((res) => {
          this.loading = false;
          if (res.errorCode == this.$commons.RespCode.success) {
            res.data.receiveTime = this.$moment(
              res.data.receiveTime,
              "YYYY-MM-DD"
            );
            this.form = res.data;
            if (
              this.info &&
              this.info.taskId &&
              this.info.taskId != this.form.taskId
            ) {
              this.form.taskId = this.info.taskId;
            }
            if (this.form.docPath) {
              var d = JSON.parse(res.data.docPath);
              var files = [];
              for (var i = 1; i <= d.length; ++i) {
                var a = d[i - 1];
                files.push({
                  uid: i,
                  name: a.title,
                  status: "done",
                  response: a,
                  url: a.url,
                });
              }
              if (res.data.status == 0 || res.data.status == 3)
                this.upLoadRemove = true;
              this.fileList = files;
            }
            this.getTask(res.data.processId);
          }
        });
      },
      async finishTask() {
        if (this.taskId == "" || this.taskId == null) {
          this.$message.error("已办理");
          return;
        }
        this.worktaskloading = true;
        var res = await workflowApi.finishTask(this.taskId);
        this.worktaskloading = false;
        if (res.errorCode === this.$commons.RespCode.success) {
          this.$message.success("操作成功");
          this.getData();
        } else {
          this.$message.error(res.errorMsg);
        }
      },
      getTask(id) {
        this.loading = true;
        this.taskId = "";
        this.allowFinish = false;
        workflowApi.getTask(id).then((res) => {
          this.loading = false;
          if (res.errorCode == this.$commons.RespCode.success) {
            if (res.data != null && res.data != "" && res.data.length > 0) {
              this.taskId = res.data[0].id;
              this.allowFinish = true;
              this.$forceUpdate();
            }
          }
        });
      },
      taskComplate(type) {
        this.form.finishDirectly = 1;
        if (type == 1) {
          this.$refs.auditdocshow11.showaudit();
        } else {
          this.$refs.auditdocshow12.showaudit();
        }
      },
      handleSubmit(status) {
        this.$refs.newsCategoryForm.validate((valid) => {
          if (valid) {
            let formData = { ...this.form, status };
            formData.receiveTime = formData.receiveTime.format("YYYY-MM-DD");
            if (this.fileList.length > 0) {
              var file = [];
              this.fileList.forEach((f) => {
                file.push({
                  title: f.response.title,
                  url: f.response.url,
                });
              });
              formData.docPath = JSON.stringify(file);
            }
            this.loading = true;
            if (this.info.id != 0) {
              formData.id = this.info.id;
            }
            delete formData.auditRecords;
            //添加
            documentreadApi
              .addOrUpdate(formData)
              .then((res) => {
                if (res.errorCode == this.$commons.RespCode.success) {
                  this.$message.success(res.errorMsg);
                  this.$emit("callback", true);
                } else {
                  this.$message.error(res.errorMsg);
                }
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            return false;
          }
        });
      },
      cancelClick() {
        this.$emit("callback", false);
      },
      showWpsRead(file) {
        let type = file.name.substring(file.name.lastIndexOf(".") + 1);
        if (
          type.toLowerCase().indexOf("doc") <= -1 &&
          type.toLowerCase().indexOf("xls") <= -1 &&
          type.toLowerCase().indexOf("ppt") <= -1
        ) {
          window.open(file.response.url, "_blank");
        } else {
          let fileId =
            "file" +
            file.url
              .substring(file.url.lastIndexOf("/") + 1, file.url.lastIndexOf("."))
              .replaceAll("-", "");
          let url = file.url.substring(file.url.indexOf("oabucket"));
          let type = file.url.substring(file.url.lastIndexOf(".") + 1);
          localStorage.removeItem("fileRrl");
          localStorage.setItem("fileRrl", url);
          localStorage.removeItem("fileName");
          localStorage.setItem("fileName", file.name);
          localStorage.removeItem("fileId");
          localStorage.setItem("fileId", fileId);
          localStorage.removeItem("fileTpye");
          localStorage.setItem("fileTpye", type);
          const jump = this.$router.resolve({
            name: "wpsRead",
            query: {
              //要传的参数
              fileName: file.name,
            },
          });

          window.open(jump.href, "_blank");
        }
      },
      showWps() {
        let templateId = "laiwenyueban";

        let innerData = Object.assign({}, this.form);
        delete innerData.auditRecords;
        delete innerData.processExecuteStatus;
        delete innerData.processId;
        delete innerData.processModelKey;
        delete innerData.processStatus;
        delete innerData.taskId;
        delete innerData.taskKey;
        delete innerData.taskRoles;
        delete innerData.taskUsers;
        delete innerData.creatorOrgId;
        innerData.receiveTime = this.$moment(
          innerData.receiveTime,
          "YYYY-MM-DD"
        ).format("YYYY年MM月DD日");

        this.form.auditRecords.forEach((a) => {
          let key =
            "taskKey." + pinyin.getFullChars(a.taskKey.split("-")[1].toString());
          if (a.taskPrintKey != null) {
            key = "taskKey." + a.taskPrintKey;
          }
          if (innerData[key] == null) {
            innerData[key] = [];
          }
          let task = {
            auditRemark: a.auditRemark,
            pic: a.auditUserSignPicture,
            name: a.auditUserName,
            time: this.$moment(a.time, "YYYY-MM-DD").format("YYYY年MM月DD日"),
            role: a.taskKey.split("-")[1].replace("意见", ":"),
            showRole:
              key == "taskKey.BanGongShiZhuRenYiJian" ||
                key == "taskKey.BanGongShiFuZhuRenYiJian"
                ? true
                : false,
          };
          innerData[key].push(task);
        });
        if (
          innerData["taskKey.FenGuanPiYueChengBanYiJian"] &&
          innerData["taskKey.FenGuanPiYueChengBanYiJian"].length > 1
        ) {
          templateId = "laiwenyueban2";
        }
        localStorage.setItem("innerData", JSON.stringify(innerData));
        localStorage.removeItem("publicFileId");
        const jump = this.$router.resolve({
          name: "wpsedit",
          query: {
            //要传的参数
            templateId: templateId,
            fileType: "public",
            openType: "create",
            fileName: "来文阅办笺_" + innerData.fromOrg,
            printType: "custom_read",
          },
        });

        window.open(jump.href, "_blank");
      },
      changeChenban(form) {
        let query = {
          id: form.id,
          otherApplyUser: form.chengbanusers,
          otherApplyUserName: form.chengbanusersName,
          extensionSetting: form.extensionSetting,
          userInfo: form.userInfo,
        };
        let extensionSettingData = query.extensionSetting
          ? Object.assign({}, JSON.parse(query.extensionSetting))
          : {};
        extensionSettingData["node_来文阅办-分管批阅承办意见"] = {
          targetUsers: form.chengbanusers,
          count: form.chengbanusers.split(",").length,
        };
        query.extensionSetting = JSON.stringify(extensionSettingData);
        let userInfo = query.userInfo ? JSON.parse(query.userInfo) : {};
        userInfo.cb = this.selCBUser;
        query.userInfo = JSON.stringify(userInfo);
        documentreadApi.changeUser(query).then((res) => {
          if (res.errorCode == this.$commons.RespCode.success) {
            // this.$message.success(res.errorMsg);
            this.$refs.auditdocshow11.showaudit();
          } else {
            this.$message.error(res.errorMsg);
          }
        });
      },
      changeBanli(form) {
        let query = {
          id: form.id,
          otherApplyUser: form.otherApplyUser + "," + form.banliusers,
          otherApplyUserName: form.otherApplyUserName + "," + form.banliusersName,
          extensionSetting: form.extensionSetting,
          userInfo: form.userInfo,
        };
        let extensionSettingData = query.extensionSetting
          ? Object.assign({}, JSON.parse(query.extensionSetting))
          : {};
        extensionSettingData["node_来文阅办-来文办理"] = {
          targetUsers: form.banliusers,
          count: form.banliusers.split(",").length,
        };
        query.extensionSetting = JSON.stringify(extensionSettingData);
        let userInfo = query.userInfo ? JSON.parse(query.userInfo) : {};
        userInfo.bl = this.selBLUser;
        query.userInfo = JSON.stringify(userInfo);
        query.annex = JSON.stringify({
          annex: [],
          uploadAnnex: this.uploadAnnex,
        });
        documentreadApi.changeUser(query).then((res) => {
          if (res.errorCode == this.$commons.RespCode.success) {
            // this.$message.success(res.errorMsg);
            this.$refs.auditdocshow12.showaudit();
          } else {
            this.$message.error(res.errorMsg);
          }
        });
      },
      uploadDoc(fileList) {
        let _this = this;
        let query = {
          id: _this.form.id,
        };
        // 根据选择办理人员时的设置，判断是否上传附件
        let annex = _this.form.annex
          ? Object.assign({}, JSON.parse(_this.form.annex))
          : {};
        if (annex.uploadAnnex && fileList.length == 0) {
          _this.$message.error("请上传附件");
          return false;
        }
        let arr = [];
        if (annex.annex && annex.annex.length > 0) {
          arr = annex.annex;
        }
        fileList.forEach((f) => {
          arr.push({
            user: localStorage.getItem(_this.$commons.User.userName),
            filepath: f.response.url,
            filename: f.name,
          });
        });
        query.annex = JSON.stringify({ annex: arr });
        _this.$confirm({
          title: "是否确认办理完成操作？",
          onOk() {
            documentreadApi.submitFile(query).then((res) => {
              if (res.errorCode == _this.$commons.RespCode.success) {
                // this.$message.success(res.errorMsg);
                _this.$refs.auditdocshow13.showaudit();
              } else {
                _this.$message.error(res.errorMsg);
              }
            });
          },
          onCancel() { },
        });
      },
    },
  };
</script>
<style scoped>
  .laiwen .ant-form-item {
    margin-bottom: 0;
  }

  .clearfix::after {
    content: "";
    display: block;
    clear: both;
  }
</style>
